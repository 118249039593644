import React from "react"
import { Container, Image } from 'semantic-ui-react'
import NavBar from "./NavBar"
import {useSelector} from "react-redux"
import {useFirestoreConnect} from "react-redux-firebase"
import parse from 'html-react-parser';
import artwork from './images/artwork-thumbnail.jpg'
import flaround from './images/fla-round.png'
import ilaround from './images/ila-round.png'

const Contact = () => {

  let option = [{collection: "page", doc: "contact"}]
  useFirestoreConnect(option)

  const contact = useSelector(
    ({ firestore: { data } }) => data.page && data.page["contact"]
  )




  return (


    <Container className="page">

    <Container className="title">
        <h1>Gakal: First languages skills register</h1>
        <div className="round-logos">
            <div className="round-logo logo-link">
            <Image onClick={()=>window.open("https://www.firstlanguages.org.au/")} className="logoImage" src={flaround} alt="First Languages Australia" />
            </div>
            <div className="round-logo">
            <Image className="logoImage" src={ilaround} alt="Indigenous Language Support" />
            </div>
        </div>
    </Container>

    <NavBar />
    <div className="pageContainer frameContainer">
    <iframe className="formFrame" src="https://docs.google.com/forms/d/e/1FAIpQLScZ7E95zOb30Vxl9T03nawp3zYC0ZkryWDl6ho0NTOF6hkFtQ/viewform" title="Gakal: First languages skills register form">
    </iframe>
    </div>
    </Container>
  )
}
export default Contact
