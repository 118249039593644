import React from "react"
import { Container, Image } from 'semantic-ui-react'
import NavBar from "./NavBar"
import {useSelector} from "react-redux"
import {useFirestoreConnect} from "react-redux-firebase"
import parse from 'html-react-parser';
import artwork from './images/artwork-thumbnail.jpg'
import flaround from './images/fla-round.png'
import ilaround from './images/ila.png'


const Home = () => {

  let option = [{collection: "page", doc: "home"}]
  useFirestoreConnect(option)

  const home = useSelector(
    ({ firestore: { data } }) => data.page && data.page["home"]
  )




  return (
    <Container className="page">

    <Container className="title">
        <h1>Gakal: First languages skills register</h1>
        <div className="round-logos">
            <div className="round-logo logo-link">
            <Image onClick={()=>window.open("https://www.firstlanguages.org.au/")} className="logoImage" src={flaround} alt="First Languages Australia" />
            </div>
            <div className="round-logo">
            <Image className="logoImage" src={ilaround} alt="Indigenous Language Support" />
            </div>
        </div>
    </Container>

    <NavBar />
    <div className="pageContainer">
    <div className="contentSegment contentSegmentRow">
      <div>{home && parse(home.info)}</div>
      <div><a className="searchButton" href="/people">Search</a></div>
    </div>

    <div className="contentSegment credits">

      <div className="imageCredits">
        <Image className="creditsImage" src={artwork} alt="Kapi tjukurla by Nora Nyutjanka Davidson" />
        <div>  <p>"Gakal" is pronounced "ga" like gut and "kal" like cull. This word is from the Yolŋu Matha languages of North East Arnhem Land, Northern Territory, and has lots of different meanings. It was chosen for this website because it can mean skill or knowledge - related to language, work, hunting, law, etc. It can be used to describe one person, or an organisation or group or clan. The word is used as a person's experience and leadership grows. This word was provided by Yolŋu staff at <a href="https://ards.com.au/" target="_blank">ARDS Aboriginal Corporation</a>.</p>
        <p>Artwork by Bettina Danganbarr</p>
        </div>
      </div>
    </div>
    </div>
    </Container>
  )
}
export default Home
