import React from 'react';
import { BrowserRouter, Route, Redirect } from 'react-router-dom'
import PersonList from './PersonList'
import Person from './Person'
import Home from './Home'
import Contact from './Contact'

function App() {
  return (
    <BrowserRouter>
      <Route path="/" component={Home} exact/>
      <Route path="/contact" exact><Redirect to="/add" /></Route>
      <Route path="/add" component={Contact} exact/>
      <Route path="/people" component={PersonList} exact/>
      <Route path="/people/:name" component={Person} exact/>
    </BrowserRouter>
  );
}

export default App;
