import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useFirestoreConnect, isLoaded } from "react-redux-firebase"
import { useSelector } from "react-redux"
import { Button, Container, Input, Image, Checkbox, Icon, Modal } from 'semantic-ui-react'
import NavBar from "./NavBar"

import flaround from './images/fla-round.png'
import ilaround from './images/ila-round.png'

const PersonList = () => {


  let option = [{ collection: "person" }, {collection:"skill"}]
  useFirestoreConnect(option)
  const rawEntries = useSelector(state => state.firestore.ordered.person)
  //console.log(rawEntries);
  const skills = useSelector(state => state.firestore.ordered.skill)

  const [people, setPeople] = useState([])
  const [sortOrder, setSortOrder] = useState(">")
  const [searchTerm, setSearchTerm] = useState("")
  const [skillsFilter, setSkillsFilter] = useState([])
  const [open, setOpen] = useState(false);
  const [openFN, setOpenFN] = useState(false);

  //set up options for dropdown menu for skills
  /*let skillsOptions = null
  if (skills && skills !== "undefined") {
    skillsOptions = skills.map((skill, i) => {
      return {"key": i, "text": skill.name, "value": skill.id}
    })
    skillsOptions.sort((a,b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0))
  }*/

  //order skills alphabetically
  let skillsOrdered=null;
  if (skills) {
    //sort alphabetically
    let temparr = [...skills].sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    //remove other from list
    skillsOrdered = temparr.filter((skill) => skill.name !== "Other");
  }

  //make copy of skills order array and add total number of people with that skill
  let skillsOrderedWithNumber=[];
  if (skills && rawEntries){
    skillsOrdered.forEach((item, i) => {
      const peopleWithSkill = rawEntries.filter(entry => entry.skill_str && entry.skill_str.indexOf(item.name)!==-1);
      skillsOrderedWithNumber.push({...item, numPeople: peopleWithSkill.length})
    });
  }

  const changeSortOrder = () => {
    const newSortOrder = (sortOrder === "<") ? ">" : "<"
    setSortOrder(newSortOrder)
  }

  useEffect(() => {
    if (!rawEntries || rawEntries === people) return
    setPeople(filterData(rawEntries))
  }, [rawEntries, searchTerm, sortOrder, skillsFilter, skills])

  const addItemToFiltered = (filtered, item) => {
    return [...filtered, { ...item }]
  }

  const getId = (category, name) => {
    const filterresult = category.filter(s=>s.name===name.trim());
    return (filterresult.length>0) ? filterresult[0].id : null;
  }

  const getName = (category, id) => {
    const filterresult = category.filter(s => s.id === id);
    return (filterresult.length>0) ? filterresult[0].name : id;
  }

  const filterData = data => {

    let filtered = []
    // Variable comparison operator technique from https://stackoverflow.com/a/10591359
    const name_operator_table = {
      '>': (a, b) => (a.name > b.name),
      '<': (a, b) => (a.name < b.name)
    }
    // go through data
    if (data) {
      data.map(item => {

        //check title matches search terms
        const titleMatch = item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase()) ? true : false


      //console.log(skillsFilter);
        //check topics match topics selection
        let skillsMatch=false;
        if (skills){
          if (item.skill){
            //skills entered via admin site (array of ids)
            skillsMatch = item.skill.some(id=> skillsFilter.includes(id))
          }
          if (item.skill_str){
            //skills entered via Google form (string of literals)
            const skills_array = item.skill_str.split(",");
            skillsMatch = skills_array.some(name=> skillsFilter.includes(getId(skills,name)))
          }
        }

        //return if nothing matches a skill and they have selected a skill
        if (skillsFilter.length > 0 && !skillsMatch) { return}

        // Filter items by search matches and public profile
        if (titleMatch && item.public==="Yes" && item.authorised!=="new") {
          filtered = addItemToFiltered(filtered, item)
        }

        filtered.sort((a, b) => name_operator_table[sortOrder](a, b) ? 1 : -1)
      }
      )
    }
    return filtered
  }

  const getSkills = (person) =>{
    let skillsArray=[];
    if (person.skill_str){
      //skills entered via Google form (string of literals)
      //add get ID here
      const skillsArrayLiterals = person.skill_str.split(",");//literals
      skillsArrayLiterals.forEach((item, i) => {
        let val = getId(skills, item);
        (val)?skillsArray.push(val):skillsArray.push(item.trim());
      });
    } else {
      if (person.skill){
        //skills entered via admin site (array of ids)
        skillsArray=person.skill;
      }
    }
    return(skillsArray)
  }


    const changeSearchTerm = e => { setSearchTerm(e.target.value)  }

    //dropdown handler
    //const handleSkillsChange = (e, { value }) => { setSkillsFilter(value)}

    //checkbox all skills handler
    const handleAllSkillsCheck = (e, data) => {
      if (data.checked) {
        //reset skills filter array
        setSkillsFilter([]);
      }
    }

    //checkbox handler
    const handleSkillsCheck = (e, data) => {
      if (data.checked) {
        //add to skills filter array
        if (skillsFilter.indexOf(data.value) === -1) {
          setSkillsFilter([...skillsFilter, data.value])
        }
      } else {
        //remove from skills filter array
        const newFilter = skillsFilter.filter((skill) => skill !== data.value);
        setSkillsFilter(newFilter);
      }
    }



    if (!isLoaded(rawEntries)&&!isLoaded(skills)){
      return(
        <p>Loading</p>
      )
    }



  return (

    <Container className="page">

    <Container className="title">
        <h1>Gakal: First languages skills register</h1>
        <div className="round-logos">
            <div className="round-logo logo-link">
            <Image onClick={()=>window.open("https://www.firstlanguages.org.au/")} className="logoImage" src={flaround} alt="First Languages Australia" />
            </div>
            <div className="round-logo">
            <Image className="logoImage" src={ilaround} alt="Indigenous Language Support" />
            </div>
        </div>
    </Container>
    <NavBar />

    <div className="pageContainer">

    <div className="contentSegment">

      <h2>People</h2>

      <div className="peopleContainer">

        <div className="leftCol filter">


            <Input className="skillsInput"  icon='search' placeholder='Search name...' onChange={changeSearchTerm} />

            <div className="skillsCheckboxes">

            <Checkbox
              className="checkboxAll"
              label="All skills"
              value="all"
              checked={skillsFilter.length===0}
              onChange={handleAllSkillsCheck}
            />


            <Modal
              onClose={() => setOpen(false)}
              onOpen={() => setOpen(true)}
              open={open}
              trigger={<Icon size='large' link className="infoCircle" name='info circle' />}
              size="small"
              >
                <Modal.Header>Search by skill</Modal.Header>
                <Modal.Content>
                  <p>Select skills from the list to find people who have ANY of these skills.</p>
                </Modal.Content>
                <Modal.Actions>
                  <Button color='black' onClick={() => setOpen(false)} content="OK"/>
                </Modal.Actions>
            </Modal>

            {skillsOrderedWithNumber && skillsOrderedWithNumber.map((skill, i) => (
              <div className="checkboxHolder">
                <Checkbox
                  className="checkboxSkill"
                  label={skill.name}
                  value={skill.id}
                  onChange={handleSkillsCheck}
                  checked={skillsFilter.includes(skill.id)}
                />
                <span className="checkboxSkillNumber">({skill.numPeople})</span>
              </div>
            ))}

            </div>

        </div>

        <div className="rightCol">

          <div className="marginBottom sortButton">
          <Button color="black" icon={sortOrder === "<" ? "arrow up" :   "arrow down"} onClick={changeSortOrder} />
          </div>


          {people && people.map(person => (

            <div key={person.id} className={`${'personDiv'} ${person.firstnations&&'personDivFN'}`}>

            <div className="personName" >

              {person.firstnations==="Yes" &&
                <Modal
                  onClose={() => setOpenFN(false)}
                  onOpen={() => setOpenFN(true)}
                  open={openFN}
                  trigger={<Icon title="First Nations" link size='small' className="fnCircle" name='dot circle' />}
                  size="mini"
                  >
                <Modal.Content>
                  <Icon title="First Nations" size='small' className="fnCircle" name='dot circle' />
                  First Nations
                </Modal.Content>
                <Modal.Actions>
                  <Button color='black' onClick={() => setOpenFN(false)} content="OK"/>
                </Modal.Actions>
                </Modal>
              }

              {person.name}

            </div>

            <div className="personInfo" >{person.role}</div>

            <div className="personInfo" >{person.organisation}</div>

            {/*List the person's skills*/}
            {skillsFilter.length>0 && getSkills(person) &&

              <div className="personInfo personSkillsDiv" >
                {getSkills(person).map((skill, i, {length}) => (
                  <span
                    key={skill}
                    className={(skillsFilter.includes(skill))?"personSkillsBold":"personSkills"}>

                    {/*Insert a space before the skill unless it's the first one in the list*/}
                    {(i!==0)? " " :""}

                    {(skill)?getName(skills, skill):""}

                    {/*Insert a comma after the skill unless it's the last one in the list*/}
                    {(length-1 !== i && length>1) ? "," :""}
                  </span>
                ))}
              </div>
            }

            <Link to={`/people/${encodeURI(person.name)}`}>More information</Link>

          </div>
          ))}

          </div>

      </div>


    </div>


    </div>
    </Container>



  )
}
export default PersonList
