import React from "react"
import { useFirestoreConnect, isLoaded } from "react-redux-firebase"
import { useSelector } from "react-redux"
import { useParams } from 'react-router-dom'
import { Container, List, Image, Icon } from 'semantic-ui-react'
import hash from "object-hash"
import parse from 'html-react-parser';
import NavBar from "./NavBar";
import flaround from './images/fla-round.png'
import ilaround from './images/ila-round.png'


const Person = () => {
  let { name } = useParams()
  let name_hash = hash(name)
  let option = [{ collection: "person", where: ["name", "==", name], storeAs: name_hash }, {collection:"skill"}];
  useFirestoreConnect(option);

  const skills = useSelector(state => state.firestore.ordered.skill)


  const getName = (category, id) => {
    return category.filter(s => s.id === id)[0].name
  }


  const person = useSelector(state => state.firestore.ordered[name_hash])
  let data = isLoaded(person) ? person[0] : null


  if (!data){
      return(
        <p>Loading</p>
      )
    }

  let skills_array = [];
  if (data.skill_str) {
    //string entered from google form
    skills_array = data.skill_str.split(",");
  } else if (data.skill){
    //backwards compatibility - array entered via admin site
    data.skill.forEach((skill, i) => {skills_array.push(getName(skills, skill));  });
    if (data.skill_other) {skills_array.push(data.skill_other); }
  }

  return (


    <Container className="page">

    <Container className="title">
        <h1>Gakal: First languages skills register</h1>
        <div className="round-logos">
            <div className="round-logo logo-link">
            <Image onClick={()=>window.open("https://www.firstlanguages.org.au/")} className="logoImage" src={flaround} alt="First Languages Australia" />
            </div>
            <div className="round-logo">
            <Image className="logoImage" src={ilaround} alt="Indigenous Language Support" />
            </div>
        </div>
    </Container>

    <NavBar />
    <div className="pageContainer">
    <div className="contentSegment contentSegmentMarginBottom">

    <Icon size='big' link className="backCircle" name='arrow alternate circle left'     onClick={()=>window.history.back()} />

    <h2> {data.name}</h2>
    <List>

      {data.firstnations&&
        <List.Item>

        {data.firstnations==="Yes" &&
          <Icon title="First Nations" size='small' className="fnCircle" name='dot circle' />
        }

        <strong>First Nations?</strong> {data.firstnations}

        </List.Item>
      }

      {data.languages&&
        <List.Item><strong>Language/s:</strong> {data.languages}</List.Item>
      }

      {data.role&&
        <List.Item><strong>Role, position or profession:</strong> {data.role}</List.Item>
      }

      {data.organisation&&
        <List.Item><strong>Organisation/Community:</strong> {data.organisation}</List.Item>
      }


      {skills_array.length > 0  &&
        <List.Item><strong>Skills, knowledge and experience:</strong>
        <ul>
          {skills_array.map(skill => (
            <li key={skill}>{skill}</li>
          ))}
        </ul>
        </List.Item>
      }

      {data.bio&&
        <List.Item><strong>Skills description or bio:</strong> {parse(data.bio)}</List.Item>
      }

      {data.quals&&
        <List.Item><strong>Qualifications and/or recognition:</strong> {data.quals}</List.Item>
      }

      {data.emailpublic &&  data.emailpublic==="Yes" &&
        <List.Item><a href={"mailto:" + data.email}>Contact {data.name}</a></List.Item>
      }
    </List>


    </div>
    </div>
    </Container>
  )
}

export default Person
